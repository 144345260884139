// @flow
//Core
import React from 'react';
import { Modal } from '@material-ui/core';
import { imagePopupStyles } from './styles';

const ImagePopup = ({
  imgPopupState,
  handleClosePopup,
}: {
  imgPopupState: Object,
  handleClosePopup: Function,
}) => {
  const styles = imagePopupStyles();

  return (
    <Modal
      open={imgPopupState.isOpen}
      onClose={handleClosePopup}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={styles.popup}
    >
      <div className={styles.popupContent}>
        <button onClick={handleClosePopup} className={styles.popupClose} />
        <img src={imgPopupState.url} alt="" />
      </div>
    </Modal>
  );
};

export default ImagePopup;
