/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const caseStudyStyles = makeStyles((theme) => ({
  hero: {
    height: 400,
    '@media (max-width: 1024px)': {
      height: 272,
    },
  },
  container: {
    color: '#101825',
    '@media (max-width: 1024px)': {
      padding: 0,
    },
  },
  breadcrumb: {
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#777A7F',
    font: `400 13px/24px ${theme.typography.fontFamily}`,
    cursor: 'pointer',
    '@media (max-width: 1024px)': {
      marginBottom: '25px',
    },
    '& a, & span': {
      display: 'block',
      color: '#777A7F',
      font: `400 13px/24px ${theme.typography.fontFamily} !important`,
    },
    ' & span': {
      margin: '0 15px',
    },
  },
  wrapper: {
    background: '#FFFFFF',
    boxShadow: '0px 12px 34px rgba(0, 0, 0, 0.15)',
    borderRadius: '24px',
    padding: '50px 80px 100px',
    marginBottom: '100px',
    marginTop: '-247px',
    '@media (max-width: 1024px)': {
      padding: '40px 35px',
      marginTop: '-145px',
    },
  },
  title: {
    display: 'flex',
    fontWeight: 900,
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
    '&:before': {
      content: "''",
      background: 'linear-gradient(135deg, #863BD4 0%, #E5258C 100%)',
      borderRadius: '4px',
      height: '26px',
      width: '11px',
      display: 'block',
      marginRight: '16px',
      marginTop: '8px',
      '@media (max-width: 1024px)': {
        marginTop: '4px',
        flex: 'none',
      },
    },
  },
  dividerWrapper: {
    marginTop: '16px',
    marginBottom: '56px',
    '@media (max-width: 1024px)': {
      marginTop: '30px',
      marginBottom: '30px',
    },
  },
  headline: {
    flexDirection: 'row',
    marginBottom: 64,
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  sectionWrapper: {
    width: '440px',
    position: 'relative',
    background:
      // "linear-gradient(#fff 0 0) padding-box, \n    linear-gradient(to right, #E5258C, #7D35D4) border-box",
      'linear-gradient(#fff 0 0) padding-box, \n    linear-gradient(to right, #8A8A8A, #8A8A8A) border-box',
    color: '#313149',
    padding: '32px 16px',
    border: '2px solid transparent',
    borderRadius: '15px',
    display: 'inline-block',
    // margin: '0 0 64px',
    '@media (max-width: 1024px)': {
      maxWidth: '100%',
    },
  },
  sectionWrapperResult: {
    background:
      'linear-gradient(#fff 0 0) padding-box, \n    linear-gradient(to right, #E5258C, #7D35D4) border-box',
  },
  section: {
    position: 'absolute',
    top: '-17px',
    padding: '6px 28px',
    background: '#E1E1E1',
    marginBottom: 'initial',
    borderRadius: '16px',
    fontWeight: 600,
    left: '0',
    right: '0',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '110px',
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 1024px)': {
      marginBottom: '25px',
    },
  },
  sectionResult: {
    color: 'white',
    background: 'linear-gradient(135deg, #E5258C 0%, #7D35D4 100%)',
  },
  arrowWrapper: {
    flexDirection: 'row',
    margin: '0 16px',
    '@media (max-width: 1024px)': {
      marginTop: '16px',
      marginBottom: '33px',
    },
  },
  arrow: {
    '@media (max-width: 1024px)': {
      transform: 'rotate(90deg)',
    },
  },
  mainImg: {
    margin: '0 0 50px',
    '@media (max-width: 1024px)': {
      margin: '70px 0 40px',
    },
    '& img': {
      width: '100%',
      height: 'auto',
      borderRadius: '16px',
    },
  },
  firstSubImage: {
    borderRadius: '16px',
    marginTop: 50,
  },
  firstSubImageDesc: {
    marginBottom: 50,
  },
  subImageDesc: {
    textAlign: 'left',
    color: 'var(--Neutral-Charcoal, #4F4F4F)',
    fontFamily: 'Noto Sans JP',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
  },
  photoGrid: {
    display: 'flex',
    marginTop: '50px',
    height: '464px',
    '@media (max-width: 1024px)': {
      height: 'auto',
      flexDirection: 'column',
    },
    '& img': {
      borderRadius: '16px',
      display: 'block',
      objectFit: 'cover',
    },
  },
  photoGridLeft: {
    marginRight: '20px',
    '@media (max-width: 1024px)': {
      marginRight: 'initial',
      marginBottom: '20px',
      width: '100%',
    },
    '& > button': {
      width: '524px',
      height: '100%',
      '@media (max-width: 1024px)': {
        width: '100%',
        height: 'auto',
      },
    },
    '& img': {
      width: '524px',
      height: '100%',
      '@media (max-width: 1024px)': {
        width: '100%',
        height: 'auto',
      },
    },
  },
  photoGridRight: {
    flex: 1,
    '& > button': {
      width: '100%',
      height: 'calc((100% - 20px) / 2)',
      '&:not(:last-child)': {
        marginBottom: '20px',
      },
    },
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  secondSubtitle: {
    color: '#101825',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  keywords: {
    display: 'block',
    flexWrap: 'nowrap',
    '@media (max-width: 1024px)': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  recommendWrapper: {
    marginTop: '100px',
    marginLeft: 'initial',
    marginRight: 'initial',
    '@media (max-width: 1024px)': {
      marginTop: '70px',
      // marginLeft: '-35px',
      // marginRight: '-35px',
    },
  },
  recommend: {
    fontWeight: 900,
    marginBottom: 80,
    '@media (max-width: 1024px)': {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  recommendSlider: {
    marginBottom: 88,
    display: 'flex',
    justifyContent: 'center',
  },
  listProblemResult: {
    listStyle: 'disc',
    paddingLeft: 40,
  },
}));
